<template>
  <!-- Start Pricing -->
  <div class="pricing" id="pricing">
    <div class="dots dots-up"></div>
    <div class="dots dots-down"></div>
    <div class="container">
      <template v-for="plan in subscriptionsData" :key="plan.id">
        <!-- free trial -->
        <div class="plan" v-if="plan.id === 4">
          <h5>30 Dayes Free Trial</h5>
          <img src="../../assets/free.png" alt="Basic-price" />
          <div class="price">
            <span class="amount">&dollar;{{ plan.price }}</span>
            <span class="time">Free Trial</span>
          </div>
          <ul>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Access Our
              Commodities
            </li>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Build Your Own
              Dashboard
            </li>
          </ul>
          <button @click="handleFreeSubscripe" v-if="!isAuthenticated">
            Try Sign Up
          </button>
        </div>
        <!-- year -->
        <div
          class="plan"
          v-if="plan.id === 3"
          :class="plan.duration === 365 ? 'popular' : ''"
        >
          <div v-if="plan.is_on_sale" class="on-sale">
            {{ plan.sale_percentage + "%" }}
          </div>
          <div v-if="plan.id === 3" class="label">Most Popular</div>
          <h5>{{ plan.name }}</h5>
          <img src="../../assets/year.png" alt="Basic-price" />
          <div class="price">
            <span :class="plan.is_on_sale ? 'custom-line' : 'amount'"
              >&dollar;{{ plan.price }}</span
            >
            <span v-if="plan.is_on_sale" class="sale-amount"
              >&dollar;{{ plan.sale_price }}</span
            >
            <span class="time">{{
              plan.id === 3 ? "Per Year" : "Per Month"
            }}</span>
          </div>
          <ul>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Access Our
              Commodities
            </li>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Build Your Own
              Dashboard
            </li>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>
              Save 2 Month
            </li>
          </ul>
          <button
            v-if="subscription?.name != '1 Year'"
            @click="handleYearlySubscripe(plan.id)"
          >
            Choose Plan
          </button>
          <button
            v-else-if="subscription?.name == '1 Month'"
            @click="handleYearlySubscripe(plan.id)"
          >
            Upgrade Plan
          </button>
          <p v-else class="sub-desc text-lg">
            Subscription End At:
            <span class="sub-date">{{ subscription?.ends_at }}</span>
          </p>
        </div>

        <!-- Month  -->
        <div class="plan" v-if="plan.id === 1">
          <div v-if="plan.is_on_sale" class="on-sale">
            {{ plan.sale_percentage + "%" }}
          </div>
          <h5>{{ plan.name }}</h5>
          <img src="../../assets/month.png" alt="Month-price" />
          <div class="price">
            <!-- <span class="amount">&dollar;{{ plan.price }}</span> -->
            <span :class="plan.is_on_sale ? 'custom-line' : 'amount'"
              >&dollar;{{ plan.price }}</span
            >
            <span v-if="plan.is_on_sale" class="sale-amount"
              >&dollar;{{ plan.sale_price }}</span
            >
            <span class="time">Per Month</span>
          </div>
          <ul>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Access Our
              Commodities
            </li>
            <li class="flex items-center gap-1">
              <i class="fa-solid fa-check primary-color"></i>Build Your Own
              Dashboard
            </li>
          </ul>
          <button
            v-if="subscription?.name != '1 Month'"
            @click="handleMonthlySubscripe(plan.id)"
          >
            Choose Plan
          </button>
          <p v-else class="sub-desc text-lg">
            Subscription End At:
            <span class="sub-date">{{ subscription?.ends_at }}</span>
          </p>
        </div>
      </template>
    </div>
  </div>
  <!-- End Pricing -->
</template>
<script>
import { mapGetters } from "vuex";
import { gsap } from "gsap";

export default {
  props: ["subscriptionsData", "subscribtionData"],
  data() {
    return {
      subscription: null,
    };
  },
  mounted() {
    this.animatePricingPlans();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getSubscribtionPlanData"]),
  },
  methods: {
    handleFreeSubscripe() {
      this.$router.push("/signup");
    },
    handleYearlySubscripe(id) {
      this.$emit("yearlySubscribe", id);
    },
    handleMonthlySubscripe(id) {
      console.log("id");
      this.$emit("monthlySubscribe", id);
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    animatePricingPlans() {
      // Additional animations for dots
      gsap.from(".dots-up", {
        opacity: 0,
        x: -100,
        duration: 1,
        ease: "power3.out",
      });
      gsap.from(".dots-down", {
        opacity: 0,
        x: 100,
        duration: 1,
        ease: "power3.out",
      });
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.subscription = user?.subscription;
  },
};
</script>

<style scoped>
.primary-color {
  color: var(--primary-color);
}

/* Start Pricing */
.pricing {
  padding-bottom: 100px;
  padding-top: 60px;
  position: relative;
}

.pricing .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
  justify-content: center;
}

.pricing .plan {
  background-color: #fff;
  box-shadow: 0 12px 20px 0 rgb(0 0 0 / 13%), 0 2px 4px 0 rgb(0 0 0 / 12%);
  position: relative;
  text-align: center;
  z-index: 1;
  transition: 0.3s;
}

.on-sale {
  position: absolute;
  top: -12px;
  left: -12px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.pricing .plan::before,
.pricing .plan::after {
  content: "";
  position: absolute;
  width: 0;
  height: 50%;
  background-color: #f6f6f6;
  z-index: -1;
  transition: 0.3s;
}

.pricing .plan::before {
  top: 0;
  left: 0;
}

.pricing .plan::after {
  right: 0;
  bottom: 0;
}
.pricing .plan:hover {
  transform: scale(1.1);
}
/* .pricing .plan:hover::before,
.pricing .plan:hover::after {
  width: 100%;
} */

@media (min-width: 1200px) {
  .pricing .plan.popular {
    top: -20px;
  }
}

.pricing .plan.popular .label {
  position: absolute;
  right: 20px;
  width: 40px;
  writing-mode: vertical-rl;
  background-color: var(--primary-color);
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 10px 35px 10px;
}

.pricing .popular .label::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  border-style: solid;
  border-width: 20px;
  border-color: transparent transparent #fff transparent;
  transition: 0.3s;
}

.pricing .popular:hover .label::before {
  border-color: transparent transparent #fff transparent;
}

.pricing .plan h5 {
  font-weight: bold;
  font-size: 24px;
  margin: 30px 0;
}

.pricing .plan img {
  width: 80px;
  margin-bottom: 30px;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}

.pricing .plan .price {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.pricing .plan .price .amount {
  display: block;
  margin-bottom: 5px;
  font-size: 60px;
  font-weight: bold;
  color: var(--primary-color);
}

.pricing .plan .price .sale-amount {
  margin-bottom: 5px;
  font-size: 60px;
  font-weight: bold;
  color: var(--primary-color);
}

.custom-line {
  font-size: 28px;
  color: #777;
  margin-bottom: 5px;
  text-decoration: line-through;
}

.pricing .plan .price .time {
  color: #777;
}

.pricing .plan ul {
  text-align: left;
}

.pricing .plan ul li {
  padding: 20px;
  border-top: 1px solid #eee;
}

.pricing .plan ul li i {
  margin-right: 10px;
  color: var(--primary-color);
  font-weight: 900;
}

.pricing .plan button {
  display: block;
  width: fit-content;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 15px 20px;
  margin: 30px auto 40px;
  border-radius: 6px;
  transition: 0.3s;
  font-size: 18px;
  font-weight: bold;
}

.pricing .plan button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
/* End Pricing */

.sub-desc {
  @apply text-schemePrimary-gray;
  margin-bottom: 40px;
}

.sub-date {
  color: var(--primary-color);
}

.dots {
  background-image: url(../../assets/dots.png);
  height: 186px;
  width: 204px;
  background-repeat: no-repeat;
  position: absolute;
}

.dots-up {
  top: 35px;
  right: 0;
}

.dots-down {
  bottom: 65px;
  left: 0;
}
</style>
