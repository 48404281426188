<template>
  <div class="spikes"></div>
  <div class="footer">
    <div class="img">
      <img src="../../assets/compass.svg" class="w-full h-full" alt="" />
    </div>
    <p class="text-xl">Copyright&copy; BuyersCompass.com 2024</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.spikes {
  position: relative;
}

.spikes::after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 30px;
  z-index: 2;
  background-image: linear-gradient(135deg, #fff 25%, transparent 25%),
    linear-gradient(225deg, #fff 25%, transparent 25%);
  background-size: 30px 30px;
}

.footer {
  height: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f5f5f5;
  overflow: hidden;
}

.footer .img {
  width: 350px;
  height: 300px;
}
</style>
