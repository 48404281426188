<template>
  <div class="relative flex justify-center items-center">
    <div
      class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 circle-loading"
    ></div>
    <img src="../../assets/loading.png" class="rounded-full h-32 w-32" />
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
};
</script>

<style scoped>
.circle-loading {
  border-color: var(--primary-color);
}
</style>
