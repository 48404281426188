// store/modules/auth.js

import axios from "axios";
const base_url = process.env.VUE_APP_BASE_URL
export default {
    state: () => ({
        token: localStorage.getItem('token') || null,
        user: JSON.parse(localStorage.getItem('user')) || null,
        isVerified: localStorage.getItem('isVerified') === 'true',
        subscribtionPlan: {},
        settings: null
    }),
    mutations: {
        SET_SETTINGS(state, settingsData) {
            state.settings = settingsData;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        CLEAR_AUTH(state) {
            state.token = null;
            state.user = null;
            state.isVerified = false;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('isVerified');
        },
        SET_SUBSCRIBTION_PLAN(state, plan) {
            state.subscribtionPlan = plan;
        },
        SET_IS_VERIFIED(state, isVerifiedEmail) {
            state.isVerified = isVerifiedEmail;
            localStorage.setItem('isVerified', isVerifiedEmail);
        }
    },
    actions: {
        async fetchSettings({ commit }) {
            try {
                const response = await axios.get(`${base_url}/settings`);
                commit('SET_SETTINGS', response.data.data);
                localStorage.setItem("settings", JSON.stringify(response.data.data))
            } catch (error) {
                console.error('Failed to fetch settings:', error);
            }
        },
        saveUserData({ commit }, { token, user }) {
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            commit('SET_IS_VERIFIED', true)
        },
        logout({ commit }) {
            commit('CLEAR_AUTH');
            delete axios.defaults.headers.common["Authorization"];
        },
        subscribe({ commit }, plan) {
            commit("SET_SUBSCRIBTION_PLAN", plan);
        },
        restoreAuth({ commit }) {
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));
            const isVerified = localStorage.getItem('isVerified') === 'true';

            if (token) {
                commit('SET_TOKEN', token);
            }
            if (user) {
                commit('SET_USER', user);
            }
            if (isVerified) {
                commit('SET_IS_VERIFIED', isVerified);
            }
        }
    },
    getters: {
        isAuthenticated: state => !!state.token && state.isVerified,
        getUser: state => state.user,
        getSubscribtionPlanData: state => state.subscribtionPlan,
        isVerified: state => state.isVerified,
        GET_SETTINGS: state => state.settings,
        getFontFamily: (state) => state.settings?.font_family,
        getPrimaryColor: (state) => state.settings?.primary_color,
    }
};