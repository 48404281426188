import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './assets/tailwind.css'

//font awesome icons
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

//date Picker
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

// Restore authentication state
store.dispatch('restoreAuth');

app.component('VueDatePicker', VueDatePicker);

app.use(VueToast, {
    position: 'top-right',
    duration: 5000,
});
app.use(store)
app.use(ElementPlus)
app.use(router)
app.mount('#app')
