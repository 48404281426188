import { createStore } from 'vuex';
import auth from './modules/auth';


export default createStore({
  state: {
    userLoged: true,
  },
  mutations: {
    
  },

  actions: {
  },
  modules: {
    auth,
  }

});
