<template>
  <Header v-if="!$route.meta.hideHeader" />
  <router-view />
</template>

<script>
import Header from "./components/general/Header.vue";
import { getSettingData } from "./services/apis";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  components: { Header },
  methods: {
    ...mapActions(["fetchSettings"]),

    applyFontFamily(fontFamily) {
      document.body.style.fontFamily = fontFamily || "sans-serif"; // Fallback to sans-serif
    },
  },
  created() {
    this.fetchSettings();
  },
  beforeMount() {
    const setting = JSON.parse(localStorage.getItem("settings"));
    this.applyFontFamily(setting.font_family);

    const root = document.documentElement;
    root.style.setProperty("--primary-color", setting.primary_color);
    root.style.setProperty("--secondary-color", setting.secondary_color);
    root.style.setProperty("--h1-size", setting.h1_size + "px");
    root.style.setProperty("--h2-size", setting.h2_size + "px");
  },
};
</script>

<style>
#app {
  font-family: "roboto-regular" "Roboto", "sans-serif";
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  background-color: white !important;
}
</style>
